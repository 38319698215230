body {
  color: #3f4254;
}
.grey-text.grey-text {
  color: #a7afb7;
}
.ql-editor {
  min-height: 100px;
  max-width: 100%;
  max-height: 200px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn.rounded,
.btn.btn-rounded {
  border-radius: 25px !important;
  border-width: 0px;
}

.btn.btn-light-danger {
  color: #f64e60;
  background-color: #ffe2e5;
  border-color: transparent;
}

.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-danger:focus:not(.btn-text),
.btn.btn-light-danger.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #f64e60;
  border-color: transparent;
}

.office-info ul li {
  display: inline;
  margin: 0px 10px;
  font-size: 16px !important;
}

@media (min-width: 992px) {
  .main-slider .slider-single.height-800 {
    height: 600px;
  }
}

.package-carousel .package-single {
  box-shadow: 0px 5px 5px 0px rgba(46, 61, 73, 0.1);
  border-radius: 50px;
}

.package-carousel .package-single:hover {
  box-shadow: 0px 5px 15px 0px rgba(46, 61, 73, 0.1);
  background-color: #eeeeff;
}

.package-single h4 {
  text-transform: none !important;
}

@media (max-width: 500px) {
  .my-table {
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
  }
}

@media (max-width: 500px) {
  .main-dashboard-screen {
    padding-top: 30px !important;
  }

  .what-type-of-cleaning {
    font-size: 2em !important;
  }

  .order-first-screen.order-container .feature-image::before {
    height: 250px !important;
  }
  .order-first-screen.order-container .feature-image {
    height: 200px !important;
    background-position: 0px 0px !important;
  }

  #kt_wrapper,
  #root {
    background-color: #ffffff;
  }

  .kt-aside {
    width: 200px !important;
  }

  .user-bio-form .text-right {
    text-align: left !important;
  }
}

.text-darker {
  color: #051d39 !important;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaaaaa !important;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaaaaa !important;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaaaaa !important;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #aaaaaa !important;
}

textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaaaaa !important;
}
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaaaaa !important;
}
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaaaaa !important;
}
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #aaaaaa !important;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #33c92d;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.my-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #33c92d;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.forced-word-wrap {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

a.no-decoration:hover {
  text-decoration: none !important;
}

a {
  color: #6a008a;
}
a:hover {
  color: #6610f2;
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
  background: linear-gradient(#33c92d, #83b739) !important;
  color: #fff !important;
  border-top: 1px solid #33c92d !important;
  border-left: 1px solid #33c92d !important;
  border-right: 1px solid #33c92d !important;
}

.react-datepicker:not(.position-top)
  .react-datepicker-container
  .react-datepicker-top::after {
  border-bottom-color: #33c92d !important;
}

.pagination li:hover {
  cursor: pointer;
}

.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
  background: #ffffff;
  box-shadow: 0 1.2px 5.6px 0 rgba(0, 0, 0, 0.05);
}

.theme-color {
  color: #006e47 !important;
}

.theme-bg {
  background-color: #006e47 !important;
}

.theme-border {
  border-color: #006e47 !important;
}
.bullet-line-list li:before {
  border: 3px solid #83b739 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #222222 !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #7cd647 !important;
}

.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin {
  background-color: #83b739 !important;
}

.MuiButton-textPrimary {
  color: #83b739 !important;
}

.login-gradient-bg {
  background: rgb(131, 183, 57) !important;
  background: linear-gradient(
    90deg,
    rgba(131, 183, 57, 0.5) 0%,
    rgba(250, 250, 250, 1) 50%
  ) !important;
}

.card {
  border-radius: 15px !important;
}

.navbar .navbar-brand-wrapper {
  background: #ffffff;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.round-dropdown-trigger {
  background-color: white !important;
  color: #006e47 !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50px !important;
}

.round-dropdown-trigger:hover {
  background-color: #006e47 !important;
  color: white !important;
}

.round-dropdown-trigger::after {
  display: none !important;
}

.w50px.w50px {
  width: 50px !important;
}

.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.nav-profile)
  > .nav-link {
  color: #fff !important;
}

.sidebar .nav .nav-item .nav-link .menu-title {
  font-size: 0.8rem !important;
}

#header + .float-verify.float-verify {
  color: #fff;
  text-align: center;
  display: none !important;

  right: -550px;
  position: fixed;
  display: inline-block !important;
  right: 50px;
  bottom: 40px;
}
#header.navbar-fixed-top + .float-verify.float-verify {
}

.main-verifybutton.main-verifybutton:hover {
  background: #ffffff !important;
  color: #006e47 !important;
  font-weight: bold;
  border: #006e47 solid 1px;
}

#scrollUp {
  display: none !important;
}
