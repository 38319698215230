.landing-page-2 {
  .proone-nav .menu > li:hover > ul {
    transform: translate3d(-100px, 0px, 0px);
  }
  .proone-nav .menu > li > ul li a:hover {
    color: #000;
  }
  .proone-nav .menu > li > ul li a {
    font-size: 12px;
  }
  .proone-nav .menu li.has-dropdown:last-child > a::after {
    right: -20px;
  }

  .value-chain-image {
    height: 250px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;

    & + .notification-content {
      margin-top: 45px;
    }
  }

  .left {
    @media (min-width: 1024px) {
      .notification-img {
        margin-left: -120px;
        left: -50px;
        position: relative;
      }
    }
  }

  .right {
    @media (min-width: 1024px) {
      .notification-img {
        margin-right: -120px;
        right: -50px;
        position: relative;
      }
    }
  }
}
