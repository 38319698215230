.main-landing-page {
  .poppins {
    font-family: "Poppins", sans-serif !important;
  }

  .mobile-nav-toggler {
    position: absolute !important;
    top: -80px !important;
  }
  .mobile-menu {
    .navigation li.dropdown .dropdown-btn {
      border-radius: 50px;
    }
  }

  .main-header .header-upper:before {
    display: none;
  }

  .main-footer {
    background-color: #daedc7;
  }
  .features-section {
    background-color: #d6f9b7;
  }
  .main-header {
    .header-upper {
      border-top: 0 !important;
      background: #ffffff !important;
    }

    .main-menu {
      .inner-container,
      .inner-container::before {
        background: #035236;
        border-radius: 0px 0px 25px 25px !important;
      }

      .inner-container {
        border-radius: 0px 0px 25px 25px !important;
        background: #006e47;
        .border-shape {
          border: none;
        }
      }

      .navigation > li:before {
        background-color: rgba(255, 255, 255, 0.25);
        border-right: 0 !important;
      }
      .navigation > li > a:hover,
      .navigation > li.current > a {
        color: #72b43e;
      }
    }
  }

  #hero-caption-area {
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      right: 0;
      background-color: rgba(116, 180, 61, 0.9);
    }
  }

  .about-section .icon-box .icon {
    color: #006e47;
  }
}
